<template>
  <div>
    <v-menu
      content-class="m-0 p-0"
      transition="scale-transition"
      offset-y
      bottom
      nudge-bottom="5"
      :left="!$vuetify.rtl"
      :right="$vuetify.rtl"
      rounded
      max-height="75%"
    >
      <template v-slot:activator="{ on, attrs }">
        <button
          v-on="on"
          v-bind="attrs"
          class="poppins btn btn--export-filter px-5"
        >
          <span class="svg-icon">
            <v-icon size="18">mdi-dns</v-icon>
          </span>
          <span>Actions</span>
          <span class="svg-icon mr-0 ml-2">
            <v-icon size="18">mdi-chevron-down</v-icon>
          </span>
        </button>
      </template>
      <div class="py-1 w-175px bg-white px-3">
        <div
          class="drop-item poppins px-3 rounded-lg cursor-pointer"
          v-for="(action, index) in types"
          :key="index"
          @click="() => handleClick(action.url)"
        >
          <div
            class="py-2 w-100 text-left drop-text poppins font-size-sm font-weight-medium"
            :class="[
              { 'mt-2': index === 0 },
              { 'mb-2': index === types.length - 1 },
            ]"
          >
            {{ action.name }}
          </div>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {
  SET_PAGE_LOADING,
  SET_REPORT_MODAL_DATA,
  SET_REPORT_MODAL_STATE,
} from "@/core/services/store/config.module";
import Swal from "sweetalert2";
import SwalService from "@/core/services/swal.service";
// import swalEdited from "@/core/services/AlertServices/SwalEdited";

export default {
  name: "ActionsButton",
  props: ["items", "types", "refresher", "resetSelectedItems"],
  methods: {
    handleClick(url) {
      if (this.items.length > 0) {
        if (url === "order_reassign_carrier_bulk") {
          this.$refs.reassignCarreir.toggleModal();
        } else if (url === "cancel") {
          Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, cancel it!",
          }).then((result) => {
            if (result.isConfirmed) {
              this.actionRequest(url);
            }
          });
        } else {
          const isConfirmationRequired = this.permissions?.find(
            (permission) => permission.name === url
          )?.is_confirmation_required;

          if (isConfirmationRequired) {
            Swal.fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: this.$t("yes"),
            }).then((result) => {
              if (result.isConfirmed) {
                this.actionRequest(url);
              }
            });
          } else {
            this.actionRequest(url);
          }
        }
      } else {
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one order!",
          icon: "warning",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "OK",
          cancelButtonColor: "#FFA800",
        });
      }
    },
    actionRequest(url) {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { ids: this.items.map((it) => it.id) };
      ApiService.post(`/fulfillment/asn/${url}`, data)
        .then(({ data }) => {
          // swalEdited();
          try {
            this.resetSelectedItems();

            if (url === "export_skus") {
              // Function to download a file
              const downloadFile = async (url, filename) => {
                try {
                  const response = await fetch(url);
                  if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                  }
                  const blob = await response.blob();
                  const blobUrl = window.URL.createObjectURL(blob);
                  const a = document.createElement("a");
                  a.style.display = "none";
                  a.href = blobUrl;
                  a.download = filename;
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(blobUrl);
                  document.body.removeChild(a);
                } catch (error) {
                  SwalService.errorMessage({ html: `Download error:${error}` });
                }
              };

              // Function to download all files sequentially
              const downloadAllFiles = async (links) => {
                for (let i = 0; i < links.length; i++) {
                  const url = links[i];
                  const filename = url.substring(url.lastIndexOf("/") + 1);
                  await downloadFile(url, filename);
                }
              };

              downloadAllFiles(data.urls);
              this.$store.commit(SET_PAGE_LOADING, false);
            } else {
              this.$store.commit(SET_REPORT_MODAL_DATA, {
                total_orders_count: data.total_count,
                failed_orders_count: data.failed_count,
                successful_orders_count: data.successful_count,
                failed_orders: data.failed,
                message: data?.message,
              });
              this.$store.commit(SET_REPORT_MODAL_STATE, true);
              // let messages = data?.messages ?? data?.failed;
              // console.log(messages);
              // // if (data.hasOwnProperty("failed")) {}
              // Swal.fire({
              //   title: !data?.successful_count ? "Warning" : "Completed",
              //   // html: data?.messages?.length
              //   html: Object.values(messages)?.length
              //     ? `<div>${data?.messages
              //         ?.map((mes) => `<div>${mes}</div>`)
              //         .join("")}</div>`
              //     : "Record modified successfully",
              //   icon: "info",
              //   showConfirmButton: false,
              //   showCancelButton: true,
              //   cancelButtonText: "OK",
              // });
              this.refresher();
            }
          } catch {
            Swal.fire({
              title: "Failed",
              html: "Opps..Something went wrong.",
              icon: "error",
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonText: "OK",
            });
          }
        })
        .catch(() => {
          // Swal.fire({
          //   title: "Error",
          //   html: `<div>${data.messages.map((mes)=> `<div>${mes}</div>`).join('')}</div>`,
          //   icon: "error",
          //   showConfirmButton: false,
          //   showCancelButton: true,
          //   cancelButtonText: "OK",
          // });
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
  computed: {
    permissions: function() {
      return this.$store.getters.getASNTablePermissions;
    },
  },
};
</script>

<style>
.drop-item:hover {
  font-weight: 600;
  background-color: #eee5ff;
  color: #8950fcff !important;
}
</style>
